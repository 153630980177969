import React from 'react';
import { motion } from 'framer-motion';

const ProfileImage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: 0.3 }}
      className="relative max-w-3xl mx-auto mb-12"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-2xl blur-xl opacity-75" />
      <img
        src="https://trustedsignalsvip.com/wp-content/uploads/2024/12/Screenshot-2024-12-23-160527.png"
        alt="Trade with Pat"
        className="relative rounded-2xl w-full shadow-2xl"
      />
    </motion.div>
  );
};

export default ProfileImage;