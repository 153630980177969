import React from 'react';
import { motion } from 'framer-motion';
import { BookOpen, ChevronRight, GraduationCap, Users, Video, Award } from 'lucide-react';
import Container from '../../ui/Container';
import Button from '../../ui/Button';
import { GradientText } from '../../ui/GradientText';

const features = [
  {
    icon: Video,
    title: 'Video Tutorials',
    description: 'Step-by-step video lessons on trading techniques'
  },
  {
    icon: Users,
    title: 'Expert Community',
    description: 'Learn from experienced traders and mentors'
  },
  {
    icon: GraduationCap,
    title: 'Trading Academy',
    description: 'Structured courses for all experience levels'
  },
  {
    icon: Award,
    title: 'Certification',
    description: 'Earn certificates as you progress'
  }
];

const EducationHero = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
      {/* Background grid pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(rgba(59,130,246,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(59,130,246,0.05)_1px,transparent_1px)] bg-[size:32px_32px] [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black_70%)]" />
      
      <Container>
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Content */}
          <div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
            >
              <BookOpen className="h-4 w-4 mr-2" />
              Trading Education
            </motion.div>

            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="text-4xl md:text-5xl font-bold text-white mb-6 leading-tight"
            >
              Learn to Trade Like a{' '}
              <GradientText>Professional</GradientText>
            </motion.h2>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-gray-300 text-lg mb-8 leading-relaxed"
            >
              Access our comprehensive educational resources designed to help you succeed in the financial markets. From beginner basics to advanced strategies, we've got you covered.
            </motion.p>

            <div className="grid sm:grid-cols-2 gap-6 mb-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 + index * 0.1 }}
                  className="flex items-start gap-4 group hover:translate-x-1 transition-transform duration-300"
                >
                  <div className="flex-shrink-0 p-2 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-lg group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
                    <feature.icon className="h-6 w-6 text-blue-400 group-hover:text-blue-300" />
                  </div>
                  <div>
                    <h3 className="text-white font-medium mb-1">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
            >
              <Button 
                to="/guides"
                className="group"
                icon={ChevronRight}
              >
                Start Learning
              </Button>
            </motion.div>
          </div>

          {/* Image */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.4 }}
            className="relative"
          >
            <div className="relative rounded-2xl overflow-hidden shadow-2xl">
              <img
                src="https://trustedsignalsvip.com/wp-content/uploads/2024/12/Screenshot-2024-12-12-223016.png"
                alt="Trading Education Platform"
                className="w-full h-auto rounded-2xl transform hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 mix-blend-overlay" />
            </div>

            {/* Decorative elements */}
            <div className="absolute -inset-4 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-3xl opacity-50 rounded-full" />
            <div className="absolute -right-8 top-1/4 w-16 h-16 bg-gradient-to-br from-blue-500/20 to-purple-500/20 backdrop-blur-lg rounded-xl border border-white/10" />
            <div className="absolute -left-8 bottom-1/4 w-20 h-20 bg-gradient-to-tr from-purple-500/20 to-blue-500/20 backdrop-blur-lg rounded-xl border border-white/10" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(59,130,246,0.1)_1px,transparent_1px)] bg-[size:20px_20px]" />
          </motion.div>
        </div>
      </Container>
    </section>
  );
};

export default EducationHero;