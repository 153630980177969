import React from 'react';
import Container from '../ui/Container';
import HeroContent from './sections/HeroContent';
import HeroStats from './sections/HeroStats';
import { motion } from 'framer-motion';
import { LineChart, BarChart2, CandlestickChart, DollarSign } from 'lucide-react';

const Hero = () => {
  return (
    <div className="relative min-h-screen bg-black overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Base gradient */}
        <div className="absolute inset-0 bg-gradient-to-b from-black via-dark-light to-black" />
        
        {/* Grid Pattern */}
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(247,167,37,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(247,167,37,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
        />

        {/* Hero image with Gradient Overlay */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="absolute inset-0 z-0"
        >
          {/* Dark gradient overlays */}
          <div className="absolute inset-0 bg-gradient-to-b from-black via-black/80 to-black" />
          
          {/* Hero image */}
          <img
            src="https://trustedsignalsvip.com/wp-content/uploads/2025/01/Screenshot-2025-01-28-203302.png"
            alt="HankoX Trading Platform"
            className="w-full h-full object-cover opacity-50"
          />
          
          {/* Additional gradient overlays for depth */}
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/80 to-transparent" />
          <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black" />

          {/* Animated gradient lines */}
          {[...Array(5)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute h-[1px] w-full bg-gradient-to-r from-transparent via-brand-gold/20 to-transparent"
              style={{
                top: `${20 + i * 15}%`,
              }}
              animate={{
                x: ['-100%', '100%'],
              }}
              transition={{
                duration: 10 + i * 2,
                repeat: Infinity,
                ease: "linear",
                delay: i * 0.5
              }}
            />
          ))}

          {/* Floating Trading Elements */}
          {[...Array(8)].map((_, i) => (
            <motion.div
              key={`float-${i}`}
              className="absolute"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
              }}
              animate={{
                y: [0, -50, 0],
                opacity: [0.2, 0.4, 0.2],
                scale: [1, 1.2, 1],
              }}
              transition={{
                duration: 5 + Math.random() * 5,
                repeat: Infinity,
                ease: "easeInOut",
                delay: i * 0.5
              }}
            >
              {i % 4 === 0 && <LineChart className="h-8 w-8 text-brand-gold/20" />}
              {i % 4 === 1 && <BarChart2 className="h-8 w-8 text-brand-gold/20" />}
              {i % 4 === 2 && <CandlestickChart className="h-8 w-8 text-brand-gold/20" />}
              {i % 4 === 3 && <DollarSign className="h-8 w-8 text-brand-gold/20" />}
            </motion.div>
          ))}

          {/* Glowing orbs */}
          <motion.div
            className="absolute top-1/4 -left-32 w-96 h-96 bg-brand-gold/5 rounded-full blur-[128px]"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.3, 0.5, 0.3],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear"
            }}
          />
          <motion.div
            className="absolute bottom-1/4 -right-32 w-96 h-96 bg-brand-gold/5 rounded-full blur-[128px]"
            animate={{
              scale: [1, 1.3, 1],
              opacity: [0.2, 0.4, 0.2],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear",
              delay: 1
            }}
          />
        </motion.div>

        {/* Vignette effect */}
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,transparent_0%,rgba(0,0,0,0.8)_100%)]" />
      </div>
      
      <Container className="relative z-10 pt-32 pb-16">
        <div className="text-center max-w-5xl mx-auto px-4">
          {/* Main Content */}
          <HeroContent />

          {/* Stats Grid */}
          <HeroStats />
        </div>
      </Container>
    </div>
  );
};

export default Hero;