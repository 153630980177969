// Trading platform URLs
export const TRADING_URL = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';
export const DEMO_URL = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';
export const MAGNOFX_URL = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';
export const DOWNLOAD_URL = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';
export const SUPPORT_URL = 'mailto:support@magnofx.com';
export const LIVE_CHAT_URL = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';

// Social media URLs
export const SOCIAL_URLS = {
  instagram: 'https://www.instagram.com/hankotradex',
  discord: 'https://discord.gg/EP394qGPDu'
} as const;

// Navigation URLs
export const NAV_URLS = {
  home: '/',
  markets: '/markets',
  trading: '/trading',
  about: '/about',
  contact: '/contact',
  mtx4: '/mtx4'
} as const;

// Default link for all trading-related actions
export const DEFAULT_TRADING_LINK = 'https://login.hankotrade.com/register?refLink=Mzc1OQ==&refRm=11';

// Spread thresholds for display
export const SPREAD_THRESHOLDS = {
  ULTRA_TIGHT: 0.1,
  TIGHT: 0.3,
  COMPETITIVE: 0.5,
  STANDARD: 1.0
};

// SEO-related constants
export const SEO_KEYWORDS = {
  PRIMARY: [
    'hankotrade',
    'hankox',
    'hankotradex',
    'best forex broker',
    'forex trading platform',
    'low spread forex'
  ],
  COMPETITORS: [
    'kot4x alternative',
    'herofx alternative',
    'better than kot4x',
    'kot4x vs hankotrade',
    'herofx vs hankotrade'
  ],
  FEATURES: [
    'ultra-low spreads',
    'zero commission forex',
    'instant execution',
    'professional trading platform',
    'forex education',
    'copy trading'
  ]
};

// Trading-specific constants
export const TRADING_FEATURES = {
  SPREADS: 'from 0.0 pips',
  LEVERAGE: 'up to 1:500',
  EXECUTION: 'instant execution',
  SUPPORT: '24/7 multilingual support',
  PLATFORMS: 'web, desktop, mobile',
  INSTRUMENTS: '50+ currency pairs'
};