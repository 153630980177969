import React from 'react';
import { motion } from 'framer-motion';
import { Monitor, Smartphone, Lock, Zap, ChevronRight, LineChart, BarChart2 } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';

const features = [
  {
    icon: LineChart,
    title: 'Advanced Charting',
    description: 'Professional-grade charting tools with multiple timeframes and indicators'
  },
  {
    icon: BarChart2,
    title: 'Market Analysis',
    description: 'Real-time market data and comprehensive analysis tools'
  },
  {
    icon: Lock,
    title: 'Secure Trading',
    description: 'Bank-grade security with encrypted transactions'
  },
  {
    icon: Zap,
    title: 'Fast Execution',
    description: 'Lightning-fast order execution with no requotes'
  }
];

const TradingPlatform = () => {
  return (
    <section className="py-24 bg-black relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Grid Pattern */}
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(37,99,235,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(37,99,235,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          transition={{ duration: 1.5 }}
        />

        {/* Diagonal Lines */}
        <div className="absolute inset-0 opacity-10">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute h-[200%] w-0.5 bg-brand-blue transform -rotate-45"
              style={{
                left: `${i * 10}%`,
                top: '-50%'
              }}
            />
          ))}
        </div>
      </div>

      <Container className="relative">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          <div>
            {/* Banners Container */}
            <div className="flex flex-wrap gap-4 mb-6">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="inline-flex items-center bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-blue/20"
              >
                <Monitor className="h-4 w-4 mr-2" />
                Professional Trading Platform
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.1 }}
                className="inline-flex items-center bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-brand-blue/20"
              >
                <Zap className="h-4 w-4 mr-2" />
                Advanced Trading Tools
              </motion.div>
            </div>

            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="text-5xl md:text-6xl font-black text-white mb-6 tracking-tighter"
            >
              PROFESSIONAL-GRADE{' '}
              <span className="text-brand-gold">TRADING TOOLS</span>
            </motion.h2>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="text-gray-400 text-lg mb-8 leading-relaxed"
            >
              Experience professional-grade trading tools designed for both novice and experienced traders. Our platform provides everything you need for successful trading.
            </motion.p>

            <div className="space-y-6 mb-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3 + index * 0.1 }}
                  className="flex items-start gap-4 group hover:translate-x-1 transition-transform duration-300"
                >
                  <div className="flex-shrink-0 p-2 bg-gradient-to-br from-brand-blue/20 to-brand-blue/20 rounded-lg group-hover:from-brand-blue/30 group-hover:to-brand-blue/30 transition-colors">
                    <feature.icon className="h-6 w-6 text-brand-blue group-hover:text-brand-blue" />
                  </div>
                  <div>
                    <h3 className="text-white font-semibold mb-1">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>

            <Button 
              size="lg"
              icon={ChevronRight}
              className="bg-brand-blue hover:bg-brand-blue-dark text-white font-black tracking-wider"
            >
              EXPLORE PLATFORM
            </Button>
          </div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="relative"
          >
            <div className="relative rounded-2xl overflow-hidden shadow-2xl">
              <img 
                src="https://trustedsignalsvip.com/wp-content/uploads/2024/12/Screenshot-2024-12-12-143059.png"
                alt="Advanced Trading Platform Interface"
                className="w-full h-auto rounded-2xl transform hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
              <div className="absolute inset-0 bg-gradient-to-r from-brand-blue/20 to-brand-blue/20 mix-blend-overlay" />
            </div>

            {/* Decorative elements */}
            <div className="absolute -inset-4 bg-gradient-to-r from-brand-blue/20 to-brand-blue/20 blur-3xl opacity-50 rounded-full" />
            <div className="absolute -right-8 top-1/4 w-16 h-16 bg-gradient-to-br from-brand-blue/20 to-brand-blue/20 backdrop-blur-lg rounded-xl border border-white/10" />
            <div className="absolute -left-8 bottom-1/4 w-20 h-20 bg-gradient-to-tr from-brand-blue/20 to-brand-blue/20 backdrop-blur-lg rounded-xl border border-white/10" />
          </motion.div>
        </div>
      </Container>
    </section>
  );
};

export default TradingPlatform;