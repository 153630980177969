import React from 'react';
import { motion } from 'framer-motion';

const MTX4NotificationImage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      className="relative"
    >
      <div className="relative rounded-xl overflow-hidden shadow-2xl">
        <img
          src="https://trustedsignalsvip.com/wp-content/uploads/2024/12/Screenshot-2024-12-12-143059.png"
          alt="MTX4 Platform Interface"
          className="w-full h-auto rounded-xl transform hover:scale-105 transition-transform duration-500"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
      </div>

      {/* Decorative elements */}
      <div className="absolute -inset-4 bg-gradient-to-r from-brand-gold/20 to-brand-gold-light/20 blur-3xl opacity-50 rounded-full" />
      <div className="absolute -right-8 top-1/4 w-16 h-16 bg-gradient-to-br from-brand-gold/20 to-brand-gold-light/20 backdrop-blur-lg rounded-xl border border-white/10" />
      <div className="absolute -left-8 bottom-1/4 w-20 h-20 bg-gradient-to-tr from-brand-gold-light/20 to-brand-gold/20 backdrop-blur-lg rounded-xl border border-white/10" />
    </motion.div>
  );
};

export default MTX4NotificationImage;